import * as React from 'react'
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';

export const seoTitle = '传感器硬件 | 产品服务 | 赑玄阁';
export const seoDescription = '第二代生毛豆空气检测智能硬件， 敏锐稳定，给你专业的空气检测体验，通过生毛豆建立大气多维度数据监测系统，集成各类感应Sensor，检测多种专业环境信息并通过3D 模型数值展示系统，其中简洁的界面环境等级一目了然，更加详细精准的数字化你的生活。';
export const browserTitle = '传感器硬件 - 产品服务 - 赑玄阁';
export const keywords = '大气多维度数据监测系统, 大气监测, 生毛豆, 空气监测, 模型数值展示';

const TypographyTitle = styled(Typography)(({theme}) => {
  return {
    fontSize: '1.6vw',
    color: '#1BAFBF',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('lg')]: {
      fontSize: "20px",
    }
  }
});
const TypographyEn = styled(Typography)(({theme}) => {
  return {
    fontSize: '1.4vw',
    whiteSpace: 'nowrap',
    color: 'rgba(255,255,255,0.6)',
    [theme.breakpoints.up('lg')]: {
      fontSize: "16px",
    }
  }
});

export const subSystemData = [
  {
    img: require('../../images/products/hardware/02.png').default,
    title: <TypographyTitle align="center" variant="subtitle1">设备研制</TypographyTitle>,
    titleEn: <TypographyEn align="center" variant="body2">Equipment<br />Development</TypographyEn>,
  },
  {
    img: require('../../images/products/hardware/03.png').default,
    title: <TypographyTitle align="center" variant="subtitle1">技术研发</TypographyTitle>,
    titleEn: <TypographyEn align="center">Technology<br />Development</TypographyEn>,
  },
  {
    img: require('../../images/products/hardware/04.png').default,
    title: <TypographyTitle align="center" variant="subtitle1">模拟预警</TypographyTitle>,
    titleEn: <TypographyEn align="center">Simulated<br />Alerts</TypographyEn>,
  },
  {
    img: require('../../images/products/hardware/05.png').default,
    title: <TypographyTitle align="center" variant="subtitle1">动态监测</TypographyTitle>,
    titleEn: <TypographyEn align="center">Dynamic<br />Monitoring</TypographyEn>,
  },
  {
    img: require('../../images/products/hardware/06.png').default,
    title: <TypographyTitle align="center" variant="subtitle1">平台开发</TypographyTitle>,
    titleEn: <TypographyEn align="center">Platform<br />Development</TypographyEn>,
  }
];
