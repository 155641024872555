import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import { useTheme } from '@mui/material/styles'
import Box from "@mui/material/Box";
import {
  TitleBox, TitleEnBox, SubTitleWhiteBox, SubTitleEnBox, CennterBox,
  Box4Title, BackBox, ProductContact
} from "../../components/Common/Base";
import ProjectHeader, { CoverContent } from "../../components/Common/ProjectHeader";
import {browserTitle, seoDescription, seoTitle, subSystemData, keywords} from "../../data/products/hardware";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid/Grid";
import config from "../../config";
import VideoJs from "../../components/Video";




const Hardware = (props) => {
  const { location } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/hardware/01.mp4').default}
        backImg={require('../../images/products/hardware/hardware01.jpg').default}
        backVideo={'products/hardware/01.m3u8'}
        title={'大气多维度数据监测系统'}
        titleEn={'Hardware Sensors'}
        titleEn2={'Atmospheric Multidimensional Data Monitoring System'}
        subTitle={'传感器硬件'}
        handleNextClick={handleNextClick}
      />
      {/*box 2*/}
      <Box
        ref={eleShow}
        sx={{
        background: '#071D26',
        pt: 6,
        pb: 6,
      }}>
        <Container
          maxWidth="xl"
          sx={{
            p: 2
          }}
        >
          <Stack
            spacing={4}
            direction={{xs: 'column', md: 'row'}}

          >
            <Box4Title
              variant="h4"
              align={matches ? 'left' : 'center'}
            >
              子系统开发流程
              <br />
              Subsystem
              {
                matches ? (
                  <br />
                ) : null
              }
              Development Process
            </Box4Title>
            <Stack
              direction={{xs: 'row'}}
              style={{flex: 1}}
              justifyContent="space-between"
              alignItems="center"
            >
              {
                subSystemData.map((item, index) => {
                  return (
                    <SubSysItem
                      key={'sub-system-data-' + index}
                      {
                        ...item
                      }
                    />
                  )
                })
              }
            </Stack>
          </Stack>
        </Container>
      </Box>
      {/* box3 */}
      <Box style={{
        backgroundSize: 'contain',
        backgroundImage: `url(${require('../../images/products/hardware/071.jpg').default})`,
      }}>
        <Box
          style={{
            height: '45vw',
            backgroundSize: 'contain',
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${require('../../images/products/hardware/07.jpg').default})`,
          }}
        >
          <Container
            maxWidth="xl"
          >
            <CennterBox
              sx={{
                width: '50%',
                height: '45vw',
                alignItems: 'flex-start',
              }}
            >
              <Box4Title variant="h5">
                生毛豆，让你过有温度的生活
              </Box4Title>
              <TitleEnBox gutterBottom variant="h6">
                THERMODO, let you live a life with temperature
                <br />
              </TitleEnBox>
              <Box sx={{
                display: {
                  xs: 'none',
                  sm: 'block',
                }
              }}>
                <SubTitleWhiteBox
                  variant="h5"
                  sx={{
                    color: '#707070'
                  }}
                >
                  第二代生毛豆空气检测智能硬件， 敏锐稳定，给你专业的空气检测体验
                </SubTitleWhiteBox>
                <SubTitleEnBox gutterBottom variant="h6">
                  The second generation of THERMODO air detection
                  intelligent hardware, sharp and stable, to give you a
                  professional air detection experience
                </SubTitleEnBox>
              </Box>
            </CennterBox>
          </Container>
        </Box>
      </Box>
      {/* box black */}
      <Box sx={{
        background: '#000000',
        display: {
          xs: 'block',
          sm: 'none',
        },
        pt: {
          xs: 2,
          sm: 4,
        },
        pb: {
          xs: 2,
          sm: 4,
          md: 8,
          lg: 12,
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{p: 2}}
        >
          <Box
            sx={{
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
            >
              第二代生毛豆空气检测智能硬件， 敏锐稳定，给你专业的空气检测体验
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              The second generation of THERMODO air detection
              intelligent hardware, sharp and stable, to give you a
              professional air detection experience
            </SubTitleEnBox>
          </Box>
        </Container>
      </Box>
      {/* box4 */}
      <BackBox
        style={{
          backgroundImage: `url(${require('../../images/products/hardware/08.jpg').default})`,
        }}
      >
        <Container
          maxWidth="xl"
        >
          <CennterBox
            style={{
              height: '45vw',
              alignItems: 'flex-start',
            }}
          >
            <Grid sx={{mb: 4}} container spacing={4}>
              <Grid
                item
                xs={12}
                sm={5}
                sx={{
                  textAlign: {
                    xs: 'center',
                    sm: 'left',
                  }
                }}
              >
                <Box4Title variant="h5">
                  随时随地，了如指掌
                </Box4Title>
                <TitleEnBox gutterBottom variant="h6">
                  Anytime, anywhere, at your fingertips
                  <br />
                  Keep track of Data
                </TitleEnBox>
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                  }
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  sx={{
                    color: '#707070'
                  }}
                >
                  随时随地掌握数据，第一时间发现问题，解决问题
                </SubTitleWhiteBox>
                <SubTitleEnBox gutterBottom variant="h6">
                  Anytime, anywhere, Monitoring live data trend
                  <br />
                  Get noticed and solve the problems any time
                </SubTitleEnBox>
              </Grid>
            </Grid>
            <Box sx={{
              height: '20vw',
            }} />
          </CennterBox>
        </Container>
      </BackBox>
      {/* box black */}
      <Box sx={{
        background: '#000000',
        display: {
          xs: 'block',
          sm: 'none',
        },
        pt: {
          xs: 2,
          sm: 4,
        },
        pb: {
          xs: 2,
          sm: 4,
          md: 8,
          lg: 12,
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{p: 2}}
        >
          <Box sx={{
          }}>
            <SubTitleWhiteBox
              variant="h5"
            >
              随时随地掌握数据，第一时间发现问题，解决问题
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Anytime, anywhere, Monitoring live data trend
              Get noticed and solve the problems any time
            </SubTitleEnBox>
          </Box>
        </Container>
      </Box>
      {/* box5 */}
      <BackBox
        sx={{
          backgroundImage: `url(${require('../../images/products/hardware/09.jpg').default})`
        }}
      >
        <Container
          maxWidth="xl"
        >
          <CennterBox
            style={{
              height: '45vw',
            }}
          >
            <Grid
              sx={{mb: 4}}
              container
              spacing={2}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={6}
                sx={{
                  textAlign: {
                    sm: 'left',
                  }
                }}
              >
                <Box4Title variant="h5">
                  专业级传感器，极度敏锐
                </Box4Title>
                <TitleEnBox gutterBottom variant="h6">
                  Professional-grade Sensor, Extremely Sensitive and accurate
                </TitleEnBox>
              </Grid>
              <Grid
                item
                xs={12}
              />
              <Grid
                item
                xs={12}
                sm={5}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                  }
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                >
                  集成各类传感器，检测多种环境信息参数
                </SubTitleWhiteBox>
                <SubTitleEnBox gutterBottom variant="h6">
                  Integrated various types of sensor to detect a variety of
                  <br />
                  professional environmental information.
                </SubTitleEnBox>
              </Grid>
            </Grid>
          </CennterBox>
        </Container>
      </BackBox>
      {/* box black */}
      <Box sx={{
        background: '#000000',
        display: {
          xs: 'block',
          sm: 'none',
        },
        pt: {
          xs: 2,
          sm: 4,
        },
        pb: {
          xs: 2,
          sm: 4,
          md: 8,
          lg: 12,
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{p: 2}}
        >
          <Box sx={{
          }}>
            <SubTitleWhiteBox
              variant="h5"
            >
              集成各类传感器，检测多种环境信息参数
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Integrated various types of sensor to detect a variety of
              professional environmental information.
            </SubTitleEnBox>
          </Box>
        </Container>
      </Box>
      {/* box6 */}
      <BackBox>
        {/*<MyVideo*/}
          {/*style={{*/}
            {/*objectFit: 'cover',*/}
          {/*}}*/}
          {/*src={require('../../images/products/hardware/10.mp4').default}*/}
          {/*autoPlay="autoPlay"*/}
          {/*loop="loop"*/}
          {/*muted*/}
        {/*>*/}
          {/*您的浏览器不支持 video。*/}
        {/*</MyVideo>*/}
        <VideoJs
          imagePath={require('../../images/products/hardware/hardware10.jpg').default}
          options={{
            autoplay: true,
            controls: false,
            // responsive: true,
            fluid: true,
            sources: [{
              src: config.MEDIA_ROOT + 'products/hardware/10.m3u8',
              type: 'application/x-mpegURL',
            }]
          }}
        />
        <CoverContent>
          <Container
            maxWidth="xl"
            style={{
              position: '',
              zIndex: 5,
            }}
          >
            <CennterBox
              style={{
                height: '45vw',
              }}
            >
              <Grid
                sx={{mb: 4}}
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={5}
                  sx={{
                    textAlign: {
                      sm: 'left',
                    }
                  }}
                >
                  <Box sx={{
                    height: '20vw',
                  }} />
                  <Box4Title
                    variant="h5"
                  >
                    自此，你多了一位健康管家
                  </Box4Title>
                  <TitleEnBox gutterBottom variant="h6">
                    Since then, you have an
                    additional health manager
                  </TitleEnBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                />
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                    }
                  }}
                >
                  <SubTitleWhiteBox
                    variant="h5"
                    style={{
                      textShadow: '#FFF 1px 1px 5px',
                      color: '#707070'
                    }}
                  >
                    多项智能检测，比你的健康管家更加了解你，全方位为你保驾护航
                  </SubTitleWhiteBox>
                  <SubTitleEnBox gutterBottom variant="h6">
                    Multiple intelligent monitoring, all-round protection for you
                  </SubTitleEnBox>
                </Grid>
              </Grid>
            </CennterBox>
          </Container>
        </CoverContent>
      </BackBox>
      {/* box black */}
      <Box sx={{
        background: '#000000',
        display: {
          xs: 'block',
          sm: 'none',
        },
        pt: {
          xs: 2,
          sm: 4,
        },
        pb: {
          xs: 2,
          sm: 4,
          md: 8,
          lg: 12,
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{p: 2}}
        >
          <Box sx={{
          }}>
            <SubTitleWhiteBox
              variant="h5"
            >
              多项智能检测，比你的健康管家更加了解你，全方位为你保驾护航
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Multiple intelligent tests, understand you better than your
              health care provider, all-round protection for you
            </SubTitleEnBox>
          </Box>
        </Container>
      </Box>
      {/* box7 */}
      <Box
        sx={{
          background: '#fff',
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid
            sx={{pb: 4}}
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Grid
              item
              xs={6}
              sx={{
                textAlign: {
                  sm: 'left',
                },
                display: {
                  xs: 'none',
                  sm: 'block',
                }
              }}
            >
              {/*<MyVideo*/}
                {/*style={{*/}
                  {/*objectFit: 'cover',*/}
                {/*}}*/}
                {/*src={require('../../images/products/hardware/11.mp4').default}*/}
                {/*autoPlay="autoPlay"*/}
                {/*loop="loop"*/}
                {/*muted*/}
              {/*>*/}
                {/*您的浏览器不支持 video。*/}
              {/*</MyVideo>*/}
              <VideoJs
                imagePath={require('../../images/products/hardware/hardware11.jpg').default}
                options={{
                  autoplay: true,
                  controls: false,
                  // responsive: true,
                  fluid: true,
                  sources: [{
                    src: config.MEDIA_ROOT + 'products/hardware/11.m3u8',
                    type: 'application/x-mpegURL',
                  }]
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                textAlign: {
                  xs: 'center',
                  sm: 'left',
                }
              }}
            >
              <Box sx={{
                height: {
                  xs: '5vw',
                  sm: '20vw'
                },

              }} />
              <Box4Title
                variant="h5"
                sx={{
                  color: '#04D94F'
                }}
              >
                所有信息一手掌握，随时随地了解
              </Box4Title>
              <TitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  color: '#04D94F'
                }}
              >
                Since then, you have an
                additional health manager
              </TitleEnBox>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                  }
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  sx={{
                    color: '#707070'
                  }}
                >
                  简洁的界面环境等级一目了然，更加详细精准的数字化你的生活。
                </SubTitleWhiteBox>
                <SubTitleEnBox gutterBottom variant="h6">
                  Simple environment interface at a glance, more detailed and accurate digitization of your life.
                </SubTitleEnBox>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                }
              }}
            >
              <img width="100%" alt="" src={require('../../images/products/hardware/phone.png').default} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box black */}
      <Box sx={{
        background: '#000000',
        display: {
          xs: 'block',
          sm: 'none',
        },
        pt: {
          xs: 2,
          sm: 4,
        },
        pb: {
          xs: 2,
          sm: 4,
          md: 8,
          lg: 12,
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{pb: 4}}
        >
          <Box sx={{
          }}>
            <SubTitleWhiteBox
              variant="h5"
            >
              简洁的界面环境等级一目了然，更加详细精准的数字化你的生活。
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Simple environment interface at a glance, more detailed and accurate digitization of your life.
            </SubTitleEnBox>
          </Box>
        </Container>
      </Box>
      {/* box8 */}
      <Box
        sx={{
          background: '#fff',
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            sx={{
              pb: {
                xs: 2,
                sm: 4
              },
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                textAlign: {
                  xs: 'center',
                  sm: 'left',
                },
                display: 'flex',
                alignItems: {
                  xs: 'center',
                  sm: 'flex-start',
                },
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Box4Title
                variant="h5"
                sx={{
                  color: '#04D94F'
                }}
              >
                3D 模型数值展示系统
              </Box4Title>
              <TitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  color: '#04D94F'
                }}
              >
                3D model value display system
              </TitleEnBox>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                  }
                }}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  sx={{
                    color: '#707070'
                  }}
                >
                  简洁的界面环境等级一目了然，更加详细精准的数字化你的生活。
                </SubTitleWhiteBox>
                <SubTitleEnBox gutterBottom variant="h6">
                  The simple interface provides a clear view of the environment situation
                  and a more detailed and accurate digitalization of your life.
                </SubTitleEnBox>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                pb: {
                  xs: 2,
                  sm: 4
                },
              }}
            >
              <img width="100%" alt="" src={require('../../images/products/hardware/12.jpg').default} />
            </Grid>
            <Grid
              item
              xs={12}
            >
              {/*<MyVideo*/}
                {/*style={{*/}
                  {/*objectFit: 'cover',*/}
                {/*}}*/}
                {/*src={require('../../images/products/hardware/13.mp4').default}*/}
                {/*autoPlay="autoPlay"*/}
                {/*loop="loop"*/}
                {/*muted*/}
              {/*>*/}
                {/*您的浏览器不支持 video。*/}
              {/*</MyVideo>*/}
              <VideoJs
                imagePath={require('../../images/products/hardware/hardware13.jpg').default}
                options={{
                  autoplay: true,
                  controls: false,
                  // responsive: true,
                  fluid: true,
                  sources: [{
                    src: config.MEDIA_ROOT + 'products/hardware/13.m3u8',
                    type: 'application/x-mpegURL',
                  }]
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box9 */}
      <Box
        sx={{
          background: {
            xs: '#000',
            sm: '#fff',
          },
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                textAlign: {
                  xs: 'center',
                  sm: 'left',
                },
                display: {
                  xs: 'flex',
                  sm: 'none',
                },
                alignItems: {
                  xs: 'center',
                  sm: 'flex-start',
                },
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Box4Title
                variant="h5"
                sx={{
                  color: '#04D94F'
                }}
              >
                控制管理中心
              </Box4Title>
              <TitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  color: '#04D94F'
                }}
              >
                Control Management Center
              </TitleEnBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                pb: {
                  xs: 2,
                  sm: 4
                },
              }}
            >
              <img width="90%" alt="" src={require('../../images/products/hardware/14.png').default} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                textAlign: {
                  xs: 'center',
                  sm: 'left',
                },
                display: {
                  xs: 'flex',
                },
                alignItems: {
                  xs: 'flex-start',
                },
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'block',
                  }
                }}
              >
                <Box4Title
                  variant="h5"
                  sx={{
                    color: '#04D94F'
                  }}
                >
                  控制管理中心
                </Box4Title>
                <TitleEnBox
                  gutterBottom
                  variant="h6"
                  sx={{
                    color: '#04D94F'
                  }}
                >
                  Control Management Center
                </TitleEnBox>
              </Box>
              <SubTitleWhiteBox
                variant="h5"
                align="left"
                sx={{
                  color: {
                    xs: '#ffffff',
                    sm: '#707070'
                  }
                }}
              >
                综合管理数据的所有信息，以最直观的方式展示最明确的内容，集中管理设备，无需人工检测，自定义分组，定制化你的管理模式。
              </SubTitleWhiteBox>
              <SubTitleEnBox align="left" gutterBottom variant="h6">
                Comprehensive management of all information on data, the most
                intuitive way to display the clearest content, centralized
                management of devices, no manual detection, custom grouping,
                customize your management model.
              </SubTitleEnBox>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box9 */}
      <Box
        sx={{
          background: {
            xs: '#000',
            sm: '#fff',
          },
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
        }}
      >
        <Container
          maxWidth="xl"
        >
          <Box4Title
            variant="h5"
            sx={{
              color: '#04D94F'
            }}
            align="center"
          >
            超多设备，为服务保驾护航
          </Box4Title>
          <TitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              color: '#04D94F'
            }}
            align="center"
          >
            Devices deployed just for providing better services
          </TitleEnBox>
          <Container
            maxWidth="xl"
          >
            <Grid
              container
              spacing={2}
              columns={{ xs: 12, md: 16 }}
            >
              <Grid
                item
                xs={6}
                md={3}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  align="left"
                  sx={{
                    color: {
                      xs: '#fff',
                      sm: '#000',
                    }
                  }}
                >
                  乌镇平安互联网小镇<br />
                  深圳东湖公园<br />
                  深圳洪湖公园<br />
                  深圳中央公园<br />
                  深圳莲花山公园<br />
                  深圳梅林公园<br />
                  深圳园博园<br />
                  深圳塘朗山公园<br />
                  深圳南山公园<br />
                </SubTitleWhiteBox>
              </Grid>
              <Grid
                item
                xs={6}
                md={5}
              >
                <SubTitleEnBox
                  align="left"
                  gutterBottom
                  variant="h6"
                >
                  Wuzhen Pingan Internet Town<br />
                  Shenzhen Donghu Park<br />
                  Shenzhen Honghu Park<br />
                  Shenzhen Central Park<br />
                  Shenzhen Lotus Hill Park<br />
                  Shenzhen Meilin Park<br />
                  Shenzhen Garden Expo Park<br />
                  Shenzhen Tanglangshan Park<br />
                  Shenzhen Nanshan Park<br />
                </SubTitleEnBox>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
              >
                <SubTitleWhiteBox
                  variant="h5"
                  align="left"
                  sx={{
                    color: {
                      xs: '#fff',
                      sm: '#000',
                    }
                  }}
                >
                  深圳深圳湾公园<br />
                  深圳羊台山森林公园<br />
                  深圳市中科实验学校<br />
                  深圳前海荔湾学校<br />
                  深圳市气象局（竹林）<br />
                  深圳中科院<br />
                  香港大学深圳医院<br />
                  深圳宝龙学校<br />
                  深圳外国语盐田校区<br />
                  ………………<br />
                </SubTitleWhiteBox>
              </Grid>
              <Grid
                item
                xs={6}
                md={5}
              >
                <SubTitleEnBox align="left" gutterBottom variant="h6">
                  Shenzhen Bay Park, Shenzhen<br />
                  Shenzhen Yangtai Mountain Forest Park<br />
                  Shenzhen Zhongke Experimental School<br />
                  Shenzhen Qianhai Liwan School<br />
                  Shenzhen Meteorological Bureau (Bamboo Forest)<br />
                  Shenzhen Chinese Academy of Sciences<br />
                  The University of Hong Kong Shenzhen Hospital<br />
                  Shenzhen Baolong School<br />
                  Shenzhen Foreign Language Yantian Campus<br />
                  ………………
                </SubTitleEnBox>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
      {/* box10 */}
      <BackBox
        style={{backgroundImage: `url(${require('../../images/products/hardware/16.jpg').default})`}}
      >
        <CennterBox
          sx={{
            height: '45vw',
          }}
        >
          <Container
            maxWidth="xl"
          >
            <Box4Title
              variant="h5"
              sx={{
                color: '#04D94F'
              }}
              align="center"
            >
              专业检测，让你更了解你的生活
            </Box4Title>
            <TitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                color: '#04D94F'
              }}
              align="center"
            >
              Professional testing so you can better understand your life
            </TitleEnBox>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block',
                }
              }}
            >
              <SubTitleWhiteBox
                variant="h5"
                align="center"
                sx={{
                  color: '#fff'
                }}
              >
                多达12项常见项目实时检测，更有其他专业级检测成分，满足你不同级别的更多需求。
              </SubTitleWhiteBox>
              <SubTitleEnBox gutterBottom variant="h6" align="center">
                Up to 12 common attributes of real-time monitoring, and other professional-grade sensor components
                to meet your different levels of more needs.
              </SubTitleEnBox>
            </Box>
            <Box>
              <img width="100%" alt="" src={require('../../images/products/hardware/15.png').default} />
            </Box>
          </Container>
        </CennterBox>
      </BackBox>
      {/* box black */}
      <Box sx={{
        background: '#000000',
        display: {
          xs: 'block',
          sm: 'none',
        },
      }}>
        <Container
          maxWidth="xl"
          sx={{pb: 4}}
        >
          <Box sx={{
          }}>
            <SubTitleWhiteBox
              variant="h5"
            >
              多达12项常见项目实时检测，更有其他专业级检测成分，满足你不同级别的更多需求。
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Up to 12 common attributes of real-time monitoring, and other professional-grade sensor components
              to meet your different levels of more needs.
            </SubTitleEnBox>
          </Box>
        </Container>
      </Box>
      {/* box11 */}
      <ProductContact />
    </Layout>
  )
}


export default Hardware;

const ItemBox = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  }
})

const ItemImg = styled('img')(() => ({
  textAlign: 'center',
  width: '6vw',
  height: '6vw',
  maxWidth: '70px',
  maxHeight: '70px',
}));

const SubSysItem = (props) => {
  const { title, titleEn, img } = props;
  return (
    <ItemBox>
      <div>
        <ItemImg
          alt={title}
          src={img}
        />
      </div>
      <div>{title}</div>
      <div>{titleEn}</div>
    </ItemBox>
  )
};


